import { CurrentUser } from '@qualio/ui-components/lib/types/CurrentUser';
import { documentApi } from '../../api/document';
import { QualioDocument } from '../../api/model/document';
import appStyles from '../../App.module.css';
import documentContentEditorStyles from '../../views/components/DocumentOverview/DocumentEditor/DocumentEditor.module.css';
import { getUTCDate } from '../DateUtils';
import { buildDocumentCoverPage } from './DocumentCoverPage';

export class DocumentExport {
  htmlForPdf: string;

  constructor(
    public qualioDocument: QualioDocument,
    public currentUser: CurrentUser,
    public documentHtml: string,
    public coverPageEnabled: boolean,
    public orientation: 'portrait' | 'landscape' | undefined,
    public controlledCopy: boolean,
    public processImagesOnExport?: boolean,
    public useLegacyExport?: boolean,
  ) {
    this.htmlForPdf = this.addDocumentWrappingHtml(
      documentHtml,
      orientation ?? 'portrait',
      coverPageEnabled,
      qualioDocument.text_sections_titles_visible,
      useLegacyExport,
    );
  }

  addDocumentWrappingHtml = (
    documentHtml: string,
    orientation: string,
    coverPageEnabled: boolean,
    sectionTitleVisible: boolean,
    useLegacyExport?: boolean,
  ) => {
    const hiddenSectionTitlesClass = !sectionTitleVisible
      ? 'document__hidden-titles'
      : '';
    const converPageEnabledClass = !coverPageEnabled
      ? 'cover-page-disabled'
      : '';
    const exportVersion = !useLegacyExport ? 'export.css' : 'export-legacy.css';
    return (
      `<link rel="stylesheet" href="${process.env.REACT_APP_EXPORT_STYLES_BASE_PATH}/ckstyles.css">` +
      `<link rel="stylesheet" href="${process.env.REACT_APP_EXPORT_STYLES_BASE_PATH}/font.css">` +
      `<link rel="stylesheet" href="${process.env.REACT_APP_EXPORT_STYLES_BASE_PATH}/${exportVersion}">` +
      `<div class="${appStyles['document-editor__app']} ${converPageEnabledClass} ${hiddenSectionTitlesClass}" >` +
      `<div id="documentEditorContent" class="${documentContentEditorStyles['document-editor__document__section-content']} document ${orientation}">` +
      '<div class="ck-restricted-editing_mode_restricted ck ck-content ck-editor__editable ck-rounded-corners ck-editor__editable_inline ck-blurred ck-focused">' +
      documentHtml +
      '</div>' +
      '</div>' +
      '</div>'
    );
  };

  render = async (): Promise<string> => {
    await this.addTokensToImageLinks();

    this.createWatermark();

    this.createCoverPage();

    return this.htmlForPdf;
  };

  createWatermark = (): void => {
    if (this.qualioDocument.status === 'Effective') {
      return;
    }
    this.htmlForPdf = `${this.getWatermark()}${this.htmlForPdf}`;
  };

  createCoverPage = (): void => {
    if (!this.coverPageEnabled) {
      return;
    }
    this.htmlForPdf =
      buildDocumentCoverPage(this.qualioDocument, this.currentUser.tz) +
      this.htmlForPdf;
  };

  addTokensToImageLinks = async (): Promise<void> => {
    const imageUrls = HtmlParsingUtils.findAttachmentImageUrlsInHtml(
      this.htmlForPdf,
    );
    if (imageUrls.length === 0) {
      return;
    }
    const token: string = await documentApi.fetchAttachmentToken(
      this.currentUser.companyId,
    );
    const attachmentQueryParams = [];
    attachmentQueryParams.push('download=false');
    if (this.processImagesOnExport) {
      attachmentQueryParams.push('process_img_data');
    }
    attachmentQueryParams.push(`token=${token}`);
    const queryStr = attachmentQueryParams.join('&');
    imageUrls.forEach((imgUrl) => {
      this.htmlForPdf = this.htmlForPdf.replace(
        `src="${imgUrl}"`,
        `src="${imgUrl}/filename?${queryStr}"`,
      );
    });
  };

  replaceImageLinksWithData = async (): Promise<void> => {
    const imageUrls = HtmlParsingUtils.findAttachmentImageUrlsInHtml(
      this.htmlForPdf,
    );
    const linksWithData: { imgUrl: string; b64Data: string }[] =
      await Promise.all(
        imageUrls.map(async (imgUrl) => ({
          imgUrl,
          b64Data: await this.retrieveAttachmentImageData(imgUrl),
        })),
      );
    linksWithData.forEach(({ imgUrl, b64Data }) => {
      this.htmlForPdf = this.htmlForPdf.replace(
        `src="${imgUrl}"`,
        `src="data:image/png;base64,${b64Data}"`,
      );
    });
  };

  getPdfHeaderHtml = (): string => {
    return `<div style="padding-left: 15mm; padding-right: 15mm; padding-top: 41px; font-size: 9.2px;">
<div style="text-align: right;">
<strong>${this.qualioDocument.type}</strong>
</div>
  <style>
    .htable {
      width: 100%;
      table-layout: fixed;
      border-bottom: 1px solid #ccc;
      border-top: 1px solid #ccc;
    }
    .htable td {
      padding: 1px 0;
      margin: 0;
      line-height: 1.42857143;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  </style>
  <table class="htable" cellpadding=0 cellspacing=0>
      <tr>
          <td style="width: 85%">${this.currentUser.companyName}</td>
          <td style="text-align: right;">${this.qualioDocument.code}</td>
      </tr>
      <tr>
          <td>${this.qualioDocument.title}</td>
          <td style="text-align: right;">Version: ${this.qualioDocument.major_version}.${this.qualioDocument.minor_version}</td>
      </tr>
  </table></div>`;
  };

  getPdfFooterHtml = (): string => {
    const date = getUTCDate();
    let copyTypeLabel: string;
    if (this.qualioDocument.status_id === 'effective' && this.controlledCopy) {
      copyTypeLabel = 'Controlled copy.';
    } else if (
      this.qualioDocument.status_id === 'effective' &&
      !this.controlledCopy
    ) {
      copyTypeLabel = 'Uncontrolled copy. Valid for 24 hours only.';
    } else {
      copyTypeLabel = `- ${this.qualioDocument.status.toLowerCase()} revision. For reference only.`;
    }
    return `<div style="padding-left: 15mm; padding-right: 15mm; padding-bottom: 4.5mm; font-size: 8px;"><table style="width: 100%;">
      <tr>
        <td style="width: 87%; line-height: 1.42857143;">Property of ${this.currentUser.companyName}. <strong>CONFIDENTIAL</strong>. Unauthorized distribution or copying prohibited. 
        Generated by ${this.currentUser.fullName} at ${date} ${copyTypeLabel}</td>
        <td style="width: 23%;text-align:right; line-height: 1.42857143;">Page <span class="pageNumber"></span> of <span class="totalPages"></span></td>
      </tr>
    </table></div>`;
  };

  getWatermark = (): string => {
    return `<div class="watermark">${this.qualioDocument.status} document</div>`;
  };

  retrieveAttachmentImageData = async (imgUrl: string): Promise<string> => {
    const attachmentId = HtmlParsingUtils.parseAttachmentIdFromUrl(imgUrl);
    return documentApi.fetchAttachmentDataAsBase64(attachmentId);
  };
}

export class HtmlParsingUtils {
  static findAttachmentImageUrlsInHtml = (docHtml: string): string[] => {
    const imageElements: string[] = docHtml.match(/<img[^>]+>/g)?.slice() ?? [];
    return [
      ...imageElements
        .map(HtmlParsingUtils.parseImageElementUrl)
        .filter((imageElement) => {
          const matches = imageElement.match(/attachments\/\d+/);
          return Boolean(matches?.length);
        }),
    ];
  };

  static parseImageElementUrl = (imgHtml: string): string => {
    const match = imgHtml.match(/src="([^"]+)"/);
    if (match && match.length === 2) {
      return match[1];
    }
    return '';
  };

  static parseAttachmentIdFromUrl = (url: string) => {
    const matches = url.match(/\/attachments\/(\d+)/);
    if (matches?.length === 2) {
      return matches[1];
    }
    return '';
  };
}

export const downloadFile = (url: string) => {
  // we use an iframe to download the file so the browser
  // won't block it. Specially important for attachments
  // zip file
  const iframe = document.createElement('iframe');
  iframe.setAttribute('src', url);
  iframe.setAttribute('style', 'display:none;');
  document.body.appendChild(iframe);
};
