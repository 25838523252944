import {
  Context,
  ContextPlugin,
  Editor,
  Plugin,
} from '@ckeditor/ckeditor5-core';
import { ViewElement } from '@ckeditor/ckeditor5-engine';
import { CKEditorInstance } from '../types/CKEditorInstance';

const DELETEKEY = 46;
const BACKSPACEKEY = 8;

export class CKEditorUtils {
  static getCloudDocumentVersion = (editor: CKEditorInstance) => {
    if (editor.plugins.has('RealTimeCollaborationClient')) {
      return editor.plugins.get('RealTimeCollaborationClient')
        .cloudDocumentVersion;
    }
    return undefined;
  };

  static getPluginIfAvailable<T extends Plugin | ContextPlugin>(
    instance: CKEditorInstance | Editor | Context,
    pluginName: string,
  ): T | null {
    if (instance?.plugins.has(pluginName)) {
      return instance.plugins.get(pluginName) as T;
    }
    return null;
  }

  static withPlugin<T extends Plugin | ContextPlugin>(
    editor: CKEditorInstance | Editor | Context,
    pluginName: string,
    fn: (p: T) => void,
  ): void {
    const p = CKEditorUtils.getPluginIfAvailable<T>(editor, pluginName);
    if (p) {
      fn(p);
    }
  }

  // To avoid the editor from deleting tables, images and other widgets when
  // the line immediately beneath them is deleted, we need to stop
  // the keydown event
  static configureEditorDelete = (editor: CKEditorInstance) => {
    if (editor) {
      editor.editing.view.document.on(
        'keydown',
        (evt, data) => {
          if (data.keyCode === BACKSPACEKEY || data.keyCode === DELETEKEY) {
            evt.stop();
          }
        },
        { priority: 'high' },
      );
    }
  };

  static getFirstAnchorTagParent = (
    element: ViewElement,
  ): ViewElement | null => {
    while (element.parent && (element.parent as ViewElement).name !== 'a') {
      element = element.parent as ViewElement;
    }
    return element.parent as ViewElement | null;
  };

  static getFirstParentHref = (element: ViewElement): string | undefined => {
    const parent = CKEditorUtils.getFirstAnchorTagParent(element);
    return parent?.getAttribute('href');
  };
}
