import {CloudServices} from '@ckeditor/ckeditor5-cloud-services';
import {CommentsRepository, NarrowSidebar, WideSidebar} from '@ckeditor/ckeditor5-comments';
import {Context} from '@ckeditor/ckeditor5-core';
import {CloudServicesCommentsAdapter} from "@ckeditor/ckeditor5-real-time-collaboration";

class QualioIndependentContext extends Context {}

QualioIndependentContext.builtinPlugins = [
  CloudServices,
  CloudServicesCommentsAdapter,
  CommentsRepository,
  NarrowSidebar,
  WideSidebar,
];

export default QualioIndependentContext;
