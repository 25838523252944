import {
  QBadge,
  QBodyLayout,
  QBox,
  QButton,
  QButtonGroup,
  QEmptyState,
  QHeader,
  QHorizontalMetadata,
  QLastModifiedMetadata,
  QOwnerMetadata,
  QPageLoader,
  QStack,
  QTab,
  QTabList,
  QTabs,
  QTag,
  QTitle,
  QualioQThemeProvider,
  QVersionMetadata,
  ToastProvider,
  useCurrentUser,
} from '@qualio/ui-components';
import { useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { documentApi } from '../../../api/document';
import diff from '../../../node-htmldiff/htmldiff';
import {
  sortVersions,
  versionIsGreaterThan,
} from '../DocumentOverview/RenderLogic';

import { DocumentStatus } from '../../../api/model/document';
import { useBodyBackground } from '../../../hooks/BodyBackground';
import { isBasicUserInAccount } from '../../../util/CurrentUser';
import { convertApiDocumentToViewableInstance } from '../../../util/DocumentApiUtils';
import { DocumentPresentationUtils } from '../../../util/DocumentPresentationUtils';
import { DocumentStatusIdToStatusConfigMap } from '../../../util/WorkspaceUtils';
import { BannerPlaceholder } from '../BannerPlaceholder';
import { DocumentCompareVersionsViewer } from '../DocumentCompareVersionsViewer/index';
import './DocumentCompareVersions.module.css';
import { parseVersion } from './util';
import { VersionSelect } from './VersionSelect';

export const DocumentCompareVersions = () => {
  useBodyBackground('document-editor-overview-refresh', true);
  const { id, baseCompare } = useParams();
  const [baseCompareVersion, setBaseCompareVersion] = useState(baseCompare);
  const [searchParams, setSearchParams] = useSearchParams();
  const compareAgainst = searchParams.get('version');
  const currentUser = useCurrentUser();
  const [compareAgainstVersion, setCompareAgainstVersion] = useState<
    string | null
  >(compareAgainst);

  const revisions = [];

  const showIncompatibleVersionsState = useMemo(() => {
    if (baseCompareVersion === compareAgainstVersion) {
      return false;
    }

    return (
      baseCompareVersion &&
      compareAgainstVersion &&
      !versionIsGreaterThan(
        parseVersion(compareAgainstVersion),
        parseVersion(baseCompareVersion),
      )
    );
  }, [baseCompareVersion, compareAgainstVersion]);

  const location = useLocation();
  const navigate = useNavigate();

  const loadDocument = useCallback(async () => {
    if (!id) {
      return;
    }

    const fetchedDocument = await documentApi.fetchByIdOrVersion(
      id,
      compareAgainstVersion ?? '',
      1,
      1,
    );

    return convertApiDocumentToViewableInstance(fetchedDocument);
  }, [id, compareAgainstVersion]);

  const loadCompareDocument = useCallback(async () => {
    if (!id) {
      return;
    }

    const fetchedDocument = await documentApi.fetchByIdOrVersion(
      id,
      baseCompareVersion ?? '',
      1,
      1,
    );

    return convertApiDocumentToViewableInstance(fetchedDocument);
  }, [id, baseCompareVersion]);

  const {
    isLoading: isLoadingDocument,
    isFetching: isFetchingDocument,
    data: latestDocument,
  } = useQuery({
    queryKey: ['newDocument', id, compareAgainstVersion],
    queryFn: loadDocument,
    refetchOnWindowFocus: false,
  });

  const {
    data: oldDocument,
    isLoading: isLoadingOldDocument,
    isFetching: isFetchingOldDocument,
  } = useQuery({
    queryKey: ['newDocument', id, baseCompareVersion],
    queryFn: loadCompareDocument,
    refetchOnWindowFocus: false,
  });

  const {
    data: versions = [],
    isLoading: isLoadingVersions,
    isFetching: isFetchingVersions,
  } = useQuery({
    queryKey: ['versions', id],
    queryFn: () => documentApi.getDocumentVersionsByDocumentId(+id!),
    refetchOnWindowFocus: false,
  });

  const handleCompareAgainstVersionsOptionChange = (version: string) => {
    setCompareAgainstVersion(version);
    searchParams.set('version', version);
    setSearchParams(searchParams);
  };

  const lhsVersions = useMemo(() => {
    return versions.filter((version) => version.version !== baseCompareVersion);
  }, [versions, baseCompareVersion]);

  const handleBaseCompareVersionsOptionChange = (version: string) => {
    if (version === '') {
      const basePath = location.pathname.split('/compare/')[0];
      if (!compareAgainstVersion || compareAgainstVersion === latestVersion) {
        navigate(basePath);
      } else {
        navigate({
          pathname: basePath,
          search: createSearchParams({
            version: compareAgainstVersion,
          }).toString(),
        });
      }
    } else {
      setBaseCompareVersion(version);

      const newPath = location.pathname.replace(baseCompare as string, version);
      navigate({
        pathname: newPath,
        search: createSearchParams({
          version: compareAgainstVersion as string,
        }).toString(),
      });
    }
  };

  const sortedVersions = useMemo(
    () => (versions ? sortVersions(versions) : []),
    [versions],
  );

  const latestVersion = useMemo(
    () =>
      sortedVersions.length > 0
        ? sortedVersions[sortedVersions.length - 1].version
        : '',
    [sortedVersions],
  );

  const documentStatusProps = useMemo(() => {
    if (latestDocument?.status_id) {
      return DocumentStatusIdToStatusConfigMap[
        latestDocument.status_id as DocumentStatus
      ];
    }
  }, [latestDocument?.status_id]);

  if (
    !latestDocument ||
    isLoadingDocument ||
    isFetchingDocument ||
    isLoadingVersions ||
    isLoadingOldDocument ||
    isFetchingOldDocument ||
    isFetchingVersions
  ) {
    return (
      <>
        <BannerPlaceholder />
        <QualioQThemeProvider>
          <QPageLoader />
        </QualioQThemeProvider>
      </>
    );
  }

  if (oldDocument && latestDocument) {
    for (
      let i = 0;
      i < Math.max(oldDocument.sections.length, latestDocument.sections.length);
      i++
    ) {
      const baseSection = oldDocument.sections[i];
      const newSection = latestDocument.sections[i];
      let revisionContent = '';
      const baseContent = DocumentPresentationUtils.stripCollabData(
        baseSection ? baseSection.content : '',
      );
      const newContent = DocumentPresentationUtils.stripCollabData(
        newSection ? newSection.content : '',
      );
      revisionContent = diff(baseContent, newContent);
      if (newSection) {
        newSection.content = revisionContent;
        revisions.push(newSection);
      } else {
        baseSection.content = revisionContent;
        revisions.push(baseSection);
      }
    }
  }

  return (
    <>
      <BannerPlaceholder />
      <QualioQThemeProvider>
        <ToastProvider enableDelegation={true}>
          <QBodyLayout.Default>
            <QHeader>
              <QTitle>
                {latestDocument.title}{' '}
                <QBadge data-cy="document-code">{latestDocument.code}</QBadge>
              </QTitle>

              <QHorizontalMetadata>
                <QTag
                  data-cy="document-status"
                  variantColor={documentStatusProps?.color}
                >
                  {documentStatusProps?.label}
                </QTag>
                <QVersionMetadata
                  data-cy="document-version"
                  value={
                    latestDocument.major_version +
                    '.' +
                    latestDocument.minor_version
                  }
                />
                <QOwnerMetadata
                  data-cy="document-owner"
                  value={latestDocument.owner.full_name}
                />
                <QLastModifiedMetadata
                  data-cy="document-last-modified"
                  value={
                    new Date(latestDocument.content_last_modified_at * 1000)
                  }
                />
              </QHorizontalMetadata>

              <QButtonGroup>
                <QButton
                  onClick={() => handleBaseCompareVersionsOptionChange('')}
                >
                  {' '}
                  Stop comparing
                </QButton>
              </QButtonGroup>
            </QHeader>
            <QStack direction="row">
              <QBox width={'100%'}>
                <QTabs>
                  <QTabList>
                    <QTab data-cy="document-tab-compare-view" isDisabled={true}>
                      Document
                    </QTab>

                    <QStack direction="row" ml="auto" spacing="2">
                      {!isBasicUserInAccount(currentUser) && (
                        <VersionSelect
                          latestVersion={latestVersion}
                          handleOptionChange={
                            handleCompareAgainstVersionsOptionChange
                          }
                          variant="LHS"
                          version={compareAgainstVersion}
                          allVersions={lhsVersions}
                        />
                      )}

                      <VersionSelect
                        latestVersion={latestVersion}
                        handleOptionChange={
                          handleBaseCompareVersionsOptionChange
                        }
                        variant="RHS"
                        version={baseCompareVersion}
                        allVersions={versions}
                        LHSVersion={compareAgainstVersion}
                      />
                    </QStack>
                  </QTabList>
                </QTabs>
              </QBox>
            </QStack>

            {showIncompatibleVersionsState ? (
              <>
                <QEmptyState
                  title="Looks like we don't support this capability"
                  subtitle="Please compare an older version against the currently selected compare version."
                />
              </>
            ) : (
              <DocumentCompareVersionsViewer sectionRevisions={revisions} />
            )}
          </QBodyLayout.Default>
        </ToastProvider>
      </QualioQThemeProvider>
    </>
  );
};
