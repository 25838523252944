import { Parser } from 'html-to-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { memo, useMemo } from 'react';

import { QualioDocumentSection } from '../../../../api/model/document';
import { ChangeControlSection } from '../../../../types/DocumentCreate';
import { DocumentPresentationUtils } from '../../../../util/DocumentPresentationUtils';
import { ChangeRequestSectionType } from '../../ChangeRequestSideDrawer/types/ChangeRequest';

type HTMLViewerProps = {
  sections:
    | QualioDocumentSection[]
    | ChangeControlSection[]
    | ChangeRequestSectionType[];
  showSectionTitles?: boolean;
  disableCheckBoxes?: boolean;
};

const htmlToReactParser = new Parser();

export const HTMLViewer = memo(
  ({
    sections,
    showSectionTitles = true,
    disableCheckBoxes,
  }: HTMLViewerProps) => {
    const { santitizeHtmlView } = useFlags();

    const htmlContent = useMemo(() => {
      const processedDocumentContent =
        DocumentPresentationUtils.determineHtmlContentFromSections(
          sections,
          false,
          false,
          false,
          showSectionTitles,
          santitizeHtmlView,
          disableCheckBoxes,
        );
      return htmlToReactParser.parse(processedDocumentContent);
    }, [sections, showSectionTitles, santitizeHtmlView, disableCheckBoxes]);

    return <>{htmlContent}</>;
  },
);
