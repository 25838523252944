import { CloudServices } from '@ckeditor/ckeditor5-cloud-services';
import { NarrowSidebar, WideSidebar } from '@ckeditor/ckeditor5-comments';
import { PresenceList } from '@ckeditor/ckeditor5-real-time-collaboration';
import { DocumentUser, QualioDocument } from '../api/model/document';
import { Tag } from '../api/model/tag';
import { MedtechUserV2 } from '../api/user';
import { exportPdfConfig } from '../plugins/PdfExport/QualioPdfExport';
import { CKEditorInstance } from '../types/CKEditorInstance';
import { qualioSuggestionThreadBuilder } from '../views/ckeditor/QualioSuggestionThreadView';
import { CKEditorConfig } from './CKEditorConfig';
import { ContextConfigFactory } from './ContextConfigFactory';
import { DocumentEditorConfigFactory } from './DocumentEditorConfigFactory';
import {
  EDITOR_AI_PLUGINS,
  getContentEditorPlugins,
  getMentionPluginConfig,
} from './Plugins';
import { AI_TOOLBAR_ITEMS, CONTENT_EDITOR_TOOLBAR_ITEMS } from './ToolbarItems';

export type SectionConfigProperties = {
  collaborationEntityId: string;
  collaborationEntityName: string;
  sectionId: number;
  documentId: number;
  documentStatus: string;
  documentOwner: DocumentUser;
  companyId: number;
  handleAutoSave: (editor: CKEditorInstance) => Promise<void>;
  commentsPermission: number;
  entityType: string;
  smartlinkEverythingEnabled: boolean;
  editorAiEnabled?: boolean;
  multilevelListEnabled: boolean;
  qualioDocument: QualioDocument;
  users?: MedtechUserV2[];
  tags?: Tag[];
  isMtbeRetrieveCollabCommentsEnabled?: boolean;
};

export type SidebarConfig = {
  sidebarElement: Element | undefined | null;
  preventScrollOutOfView: boolean;
};

export type PresenceListConfig = {
  containerElement: Element | undefined | null;
};

export type ContextConfigProperties = {
  collaborationToken: string;
  sidebarConfig: SidebarConfig;
  presenceListConfig: PresenceListConfig;
  editorBundleVersion?: string;
  editorAiEnabled?: boolean;
};

export type IndependentContextConfigProperties = {
  fileDocument: QualioDocument;
  collaborationToken: string;
  commentsPermission: number;
  sidebarConfig: SidebarConfig;
  users?: MedtechUserV2[];
  tags?: Tag[];
  isMtbeRetrieveCollabCommentsEnabled?: boolean;
};

export const getEditorConfig = (
  sectionConfigProperties: SectionConfigProperties,
): CKEditorConfig => {
  let configFactory = new DocumentEditorConfigFactory();
  const contentEditorPlugins = getContentEditorPlugins(
    sectionConfigProperties.smartlinkEverythingEnabled,
    sectionConfigProperties.multilevelListEnabled,
  );
  configFactory = configFactory
    .withCompanyId(sectionConfigProperties.companyId)
    .withPlugins(contentEditorPlugins)
    .withAutosave((editor: CKEditorInstance): Promise<void> => {
      // Must return a promise which resolves upon successful save
      return sectionConfigProperties.handleAutoSave(editor);
    })
    .withComments(
      sectionConfigProperties.commentsPermission,
      sectionConfigProperties.qualioDocument,
      sectionConfigProperties.users,
      sectionConfigProperties.tags,
      sectionConfigProperties.isMtbeRetrieveCollabCommentsEnabled,
    )
    .withCollaboration(
      `company-${sectionConfigProperties.companyId}-${sectionConfigProperties.collaborationEntityName}-${sectionConfigProperties.collaborationEntityId}-section-${sectionConfigProperties.sectionId}`,
    )
    .withTrackChanges(
      qualioSuggestionThreadBuilder(
        sectionConfigProperties.qualioDocument,
      ) as any,
    )
    .withPDFExport(exportPdfConfig)
    .withMention(
      getMentionPluginConfig(
        sectionConfigProperties.companyId,
        sectionConfigProperties.smartlinkEverythingEnabled,
      ),
    )
    .withSectionId(sectionConfigProperties.sectionId)
    .withDocumentId(sectionConfigProperties.documentId)
    .withEntityType(sectionConfigProperties.entityType)
    .withLists(sectionConfigProperties.multilevelListEnabled);

  if (sectionConfigProperties.editorAiEnabled) {
    configFactory = configFactory
      .withPlugins([...EDITOR_AI_PLUGINS, ...contentEditorPlugins])
      .withAI(sectionConfigProperties.companyId);
  }

  return configFactory.getConfig();
};

export const getContextConfig = (
  contextConfigProperties: ContextConfigProperties,
): any => {
  let contextConfigFactory = new ContextConfigFactory();

  contextConfigFactory = contextConfigFactory
    .withCloudService(
      contextConfigProperties.collaborationToken,
      contextConfigProperties.editorBundleVersion,
    )
    .withPlugins([CloudServices, NarrowSidebar, WideSidebar, PresenceList])
    .withToolbarItems(CONTENT_EDITOR_TOOLBAR_ITEMS)
    .withSidebar(contextConfigProperties.sidebarConfig)
    .withPresenceList(contextConfigProperties.presenceListConfig);

  if (contextConfigProperties.editorAiEnabled) {
    contextConfigFactory = contextConfigFactory.withToolbarItems([
      ...AI_TOOLBAR_ITEMS,
      ...CONTENT_EDITOR_TOOLBAR_ITEMS,
    ]);
  }

  return contextConfigFactory.getConfig();
};

export const getIndependentContextConfig = ({
  collaborationToken,
  sidebarConfig,
  fileDocument,
  commentsPermission,
  users,
  tags,
  isMtbeRetrieveCollabCommentsEnabled,
}: IndependentContextConfigProperties): any => {
  const contextConfigFactory = new ContextConfigFactory();
  return contextConfigFactory
    .withCloudService(collaborationToken)
    .withSidebar(sidebarConfig)
    .withContextCollaboration(
      `company-${fileDocument.company_id}-document-${fileDocument.id}-section-${fileDocument.sections[0].id}`,
    )
    .withComments(
      commentsPermission,
      fileDocument,
      users,
      tags,
      isMtbeRetrieveCollabCommentsEnabled,
    )
    .withCompanyId(fileDocument.company_id)
    .withDocumentId(fileDocument.id)
    .getConfig();
};
