import {CloudServices} from '@ckeditor/ckeditor5-cloud-services';
import {WideSidebar, NarrowSidebar } from '@ckeditor/ckeditor5-comments';
import { Context } from '@ckeditor/ckeditor5-core';
import { PresenceList } from '@ckeditor/ckeditor5-real-time-collaboration';

class QualioContext extends Context {}
QualioContext.builtinPlugins = [
  CloudServices,
  NarrowSidebar,
  WideSidebar,
  PresenceList,
];

export default QualioContext;
