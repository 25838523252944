import { DecoupledEditor } from '@ckeditor/ckeditor5-editor-decoupled';
import { CONTENT_EDITOR_PLUGINS } from '../config/Plugins';
import { QualioDocumentMatrixIdMention } from '../plugins/Mention/QualioDocumentMatrixIdMention';

class QualioEditor extends DecoupledEditor {}

let builtinPluginsInited = false;

export const getQualioEditor = (smartlinkEverythingEnabled: boolean) => {
  if (builtinPluginsInited) {
    return QualioEditor;
  }

  if (smartlinkEverythingEnabled) {
    QualioEditor.builtinPlugins = [...CONTENT_EDITOR_PLUGINS, CKEditor5.smartlink.QualioSmartlink];
  } else {
    QualioEditor.builtinPlugins = [...CONTENT_EDITOR_PLUGINS, QualioDocumentMatrixIdMention];
  }

  builtinPluginsInited = true;

  return QualioEditor;
};

export default getQualioEditor;
