import { Tooltip } from '@chakra-ui/react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { memo, useCallback, useContext, useMemo } from 'react';

import { useCurrentUser } from '@qualio/ui-components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { QualioDocumentSection } from '../../../../../../api/model/document';
import { CKEditorConfig } from '../../../../../../config/CKEditorConfig';
import { getEditorConfig } from '../../../../../../config/DocumentEditorConfig';
import { getQualioEditor } from '../../../../../../editor/QualioEditor';
import {
  CKE_EDITOR_ERROR,
  logCustomError,
} from '../../../../../../messages/LogErrorMessages';
import { CKEditorInstance } from '../../../../../../types/CKEditorInstance';
import { ChangeControlSection } from '../../../../../../types/DocumentCreate';
import { EditorMode } from '../../../../../../util/EditModeUtils';
import { DocumentOverviewContext } from '../../../Context';

export interface SectionedContentEditorProps {
  section: QualioDocumentSection | ChangeControlSection;
  handleAutoSave: (editor: CKEditorInstance) => Promise<void>;
  handleEditorChanges?: () => void;
  showSectionTitles: boolean;
  entityType: 'document.section' | 'change.section';
  collaborationEntityId: string;
  collaborationEntityName: string;
}

const determineTitleClasses = (
  showTitle: boolean,
  inEditMode: boolean,
): string => {
  let classes = 'section-heading';
  if (!inEditMode && !showTitle) {
    classes += ' section-heading__hide';
  }
  if (inEditMode && !showTitle) {
    classes += ' section-heading__placeholder';
  }
  return classes;
};

export const SectionedContentEditor = memo(
  ({
    section,
    handleAutoSave,
    showSectionTitles,
    entityType,
    collaborationEntityId,
    collaborationEntityName,
  }: SectionedContentEditorProps) => {
    const {
      qualioDocument,
      currentEditorMode,
      commentsPermission,
      users,
      tags,
    } = useContext(DocumentOverviewContext);
    const currentUser = useCurrentUser();
    const {
      editorAi,
      smartlinkEverything,
      multilevelListEnabled,
      mtbeRetrieveCollabComments: isMtbeRetrieveCollabCommentsEnabled,
    } = useFlags();
    const aiEnabled = editorAi || currentUser.company.ai_enabled;

    const config: CKEditorConfig = useMemo(() => {
      const config = getEditorConfig({
        collaborationEntityId,
        collaborationEntityName,
        sectionId: section.id,
        documentId: qualioDocument.id,
        documentStatus: qualioDocument.status,
        documentOwner: qualioDocument.owner,
        companyId: currentUser.companyId,
        handleAutoSave,
        commentsPermission,
        entityType,
        smartlinkEverythingEnabled: smartlinkEverything,
        editorAiEnabled: aiEnabled,
        multilevelListEnabled,
        qualioDocument,
        users,
        tags,
        isMtbeRetrieveCollabCommentsEnabled,
      });

      return {
        initialData: section.content,
        ...config,
      };
    }, [
      qualioDocument,
      collaborationEntityId,
      collaborationEntityName,
      section,
      handleAutoSave,
      commentsPermission,
      currentUser,
      entityType,
      aiEnabled,
      smartlinkEverything,
      multilevelListEnabled,
      users,
      tags,
      isMtbeRetrieveCollabCommentsEnabled,
    ]);

    const sectionTitle = showSectionTitles
      ? `${section.position}. ${section.title}`
      : `Section ${section.position}`;
    const titleClasses = determineTitleClasses(
      showSectionTitles,
      currentEditorMode !== EditorMode.VIEW,
    );

    const onEditorError = useCallback(() => {
      return (e: any) => {
        logCustomError(CKE_EDITOR_ERROR, { error: e.toString() });
      };
    }, []);

    return (
      <div>
        <h1 className={titleClasses}>
          <span className="locked-section-headers" data-cy={'section-title'}>
            {sectionTitle}
            {!showSectionTitles && (
              <Tooltip
                color="gray"
                placement="right"
                label="Only visible while editing"
              >
                <i
                  data-cy="hidden-section-icon"
                  className="fa fa-eye-slash"
                ></i>
              </Tooltip>
            )}
          </span>
        </h1>

        <CKEditor
          editor={getQualioEditor(smartlinkEverything)}
          config={config}
          onError={onEditorError}
        />
      </div>
    );
  },
);
