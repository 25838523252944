import { useEffect, useMemo, useRef } from 'react';

import {
  createQColumnHelper,
  DataProvider,
  QDataTable,
  QEmptyState,
  Selecting,
} from '@qualio/ui-components';

import { TagStatusMapping } from '@qualio/ui-components/lib/QOrganisms/Table/Cells/types';
import { DocumentStatus, QualioDocument } from '../../../api/model/document';
import { Tag } from '../../../api/model/tag';
import { DocumentStatusIdToStatusConfigMap } from '../../../util/WorkspaceUtils';

type WorkspaceListProps = {
  fetchedTags: Tag[];
  setIsTableReadyForExport: (isReady: boolean) => void;
  selectedDocumentsMap: Map<number, QualioDocument>;
};

export const buildDocMap = (
  selectedIds: string[],
  qualioDocuments: QualioDocument[] | undefined,
  selectedDocumentsMap: Map<number, QualioDocument>,
) => {
  const newMap = new Map<number, QualioDocument>([]);
  if (qualioDocuments) {
    selectedIds.forEach((id) => {
      const idNumber = parseInt(id);
      const qualioDocument =
        qualioDocuments.find((doc) => doc.id === idNumber) ||
        selectedDocumentsMap.get(idNumber);
      if (qualioDocument) {
        newMap.set(idNumber, qualioDocument);
      }
    });
  }
  return newMap;
};

const buildUrl = (id: number | undefined) => {
  return `/workspace/documents/${id}`;
};

export const WorkspaceList = ({
  fetchedTags,
  setIsTableReadyForExport,
  selectedDocumentsMap,
}: WorkspaceListProps) => {
  const columnHelper = createQColumnHelper<QualioDocument>();
  const selection = Selecting.useSelectionMaybe();
  const prevSelectedDocumentsMap = useRef<Map<number, QualioDocument>>(
    new Map<number, QualioDocument>(),
  );

  useEffect(() => {
    const previouslySelectedDocumentIds = [
      ...prevSelectedDocumentsMap.current.keys(),
    ];
    const selectedDocumentIds = [...selectedDocumentsMap.keys()];
    const hasSelectionChanged =
      previouslySelectedDocumentIds.toString() !==
      selectedDocumentIds.toString();

    if (hasSelectionChanged) {
      const selectionRecord = selectedDocumentIds.reduce(
        (acc: { [id: number]: true }, id) => {
          acc[id] = true;
          return acc;
        },
        {},
      );
      selection?.setSelection(selectionRecord);
    }

    prevSelectedDocumentsMap.current = selectedDocumentsMap!;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDocumentsMap]);

  const handleTitleAction = (qualioDocument: QualioDocument) => {
    return buildUrl(qualioDocument.id);
  };

  const columns = useMemo(() => {
    return [
      columnHelper.id((document) => document.code, {
        header: 'ID',
        id: 'code',
        width: '100px',
      }),
      columnHelper.textLink((document) => document.title, handleTitleAction, {
        header: 'Title',
        id: 'title',
        width: '200px',
      }),
      columnHelper.text((document) => document.type, {
        header: 'Type',
        id: 'type',
        width: '200px',
      }),
      columnHelper.text((document) => document.owner, {
        header: 'Owner',
        id: 'author_name',
        width: '150px',
      }),
      columnHelper.tag(
        (document) => {
          const filteredTags = fetchedTags.filter((fetchedTag) =>
            document.tag_ids.includes(fetchedTag.id),
          );
          return filteredTags.map((tag) => tag.name);
        },
        {
          statuses: { '*': 'default' },
          header: 'Tags',
          enableSorting: false,
          width: '250px',
        },
      ),
      columnHelper.text(
        (document) => `${document.major_version}.${document.minor_version}`,
        {
          header: 'Version',
          id: 'version',
          width: '96px',
        },
      ),
      columnHelper.status(
        (document) =>
          DocumentStatusIdToStatusConfigMap[
            document.status_id as DocumentStatus
          ].label,
        {
          header: 'Status',
          id: 'status_id',
          width: '140px',
          enableSorting: true,
          statuses: Object.values(DocumentStatusIdToStatusConfigMap).reduce(
            (acc: TagStatusMapping, statusConfig) => {
              acc[statusConfig.label] = statusConfig.color;
              return acc;
            },
            {},
          ),
        },
      ),
    ];
  }, [fetchedTags, columnHelper]);

  const { data: qualioDocuments, isLoading } =
    DataProvider.useDataProvider<QualioDocument>();

  useEffect(() => {
    setIsTableReadyForExport(Boolean(!isLoading && qualioDocuments?.length));
  }, [qualioDocuments, isLoading, setIsTableReadyForExport]);

  return (
    <QDataTable columns={columns} getRowId={(row) => row.id.toString()}>
      <QEmptyState
        title="No documents found!"
        subtitle="There are no approved or effective documents in this instance."
        data-cy="workspace-table-empty-state"
      />
    </QDataTable>
  );
};
